import React from "react";
import Text from "./../parts/Text";
import Footer from "./../parts/Footer";
import Top from "./../parts/Top";
import StickyTop from "./../parts/StickyTop";
const data = require("./../../data/graphic.json");

function Graphic(props) {
  const localization = props.match.url.split("/")[1];
  return (
    <>
      <StickyTop match={props.match} />
      <Top match={props.match} />
      <div className="wrapper">
        <h1>{localization === "it" ? "Mostre di grafica" : "Graphic Exhibitions"}</h1>
        {data[localization].text.map((paragraph, key) => (
          <Text key={key} text={paragraph} />
        ))}
        <div className="img-square-container">
          <div className="square-graphic"></div>
        </div>
        <ul className="decorated-list">
          {data.it.exhibitions.map((y, key) => (
            <li key={key}>
              <h2>{y.year}</h2>
              {y.editions.map((ed, key) => (
                <div className="edition" key={key}>
                  "<span className="title">{ed.title}</span>"{ed.gallery ? ", " + ed.gallery : null}
                  {ed.city ? (
                    <>
                      , <span className="city">{ed.city}</span>
                    </>
                  ) : null}
                </div>
              ))}
            </li>
          ))}
        </ul>
      </div>
      <Footer />
    </>
  );
}

export default Graphic;
