import React, { useState, useEffect, useContext } from "react";
import { MenuContext } from "../../context/Menu";
import LanguageLinks from "./LanguageLinks";

function StickyTop(props) {
  const [scrollY, setScrollY] = useState(
    document.body.scrollTop || document.documentElement.scrollTop
  );
  const { state, dispatch } = useContext(MenuContext);
  let urlArray = props.match.url.split("/");
  const currentLoc = urlArray[1];
  urlArray.splice(0, 2);
  const delocalizedPath = urlArray.join("/");
  //console.log(currentLoc, delocalizedPath);
  function setScroll() {
    setScrollY(document.body.scrollTop || document.documentElement.scrollTop);
  }
  const toggleMenu = () => dispatch({ type: "toggle" });
  useEffect(() => {
    window.addEventListener("scroll", setScroll);
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  });
  return (
    <div className={(scrollY > 250 || state.visible) ? "sticky-heading" : "sticky-heading hidden"}>
      <div className="contents">
        <div className="logo-small" />
        <div className="language">
          <span
            className={state.visible ? "clickable bold" : "clickable"}
            onClick={toggleMenu}
          >
            Menu
          </span>
          <LanguageLinks loc={currentLoc} path={delocalizedPath} />
        </div>
      </div>
    </div>
  );
}

export default StickyTop;
