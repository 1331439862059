import React from "react";

function RichList(props) {
  const title = (title) => {
    return (
      <>
        <span className="title">{title}</span>,
      </>
    );
  };
  return (
    <>
      <ul className="decorated-list">
        {props.data.map((item, key) =>
          item.img_uri ? (
            <li className="rich" key={key}>
              <div className="image">
                <img src={require(`./../../assets/articles/${item.img_uri}`)} alt={item.title} />
              </div>
              <div className="text">
                {item.author ? (
                  <>
                    {item.author}
                    <br />
                    <br />
                  </>
                ) : null}
                {item.title ? (
                  <>
                    {title(item.title)}
                    <br />
                    <br />
                  </>
                ) : null}
                {item.publication ? <>"{item.publication}"</> : null}
                <br />
                <br />
                {item.number}
              </div>
            </li>
          ) : (
            <li key={key}>
              {item.author ? item.author + ", " : null}
              {item.title ? title(item.title) : null}
              {item.publication ? <>"{item.publication}",</> : null} {item.number}
            </li>
          )
        )}
      </ul>
    </>
  );
}

export default RichList;
