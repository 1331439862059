import React, { useContext } from "react";
import { MenuContext } from "../../../context/Menu";
import FirstLevel from "./FirstLevel";

function Menu(props) {
  const { state } = useContext(MenuContext);
  let urlArray = props.match.url.split("/");
  let currentLoc = urlArray[1];
  return (
    <div className={state.visible ? "menu" : "menu hidden"}>
      <FirstLevel loc={currentLoc} history={props.history} />
    </div>
  );
}

export default Menu;
