import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { MenuContextProvider } from "./context/Menu";
import FourOhFour from "./components/routes/404";
import Biography from "./components/routes/Biography";
import Index from "./components/routes/Index";
import Works from "./components/routes/Works";
import Where from "./components/routes/Where";
import Articles from "./components/parts/Articles";
import Article from "./components/routes/Article";
import Menu from "./components/parts/Menu/Menu";
import Graphic from "./components/routes/Graphic";
import Personals from "./components/routes/Personals";
import Collectives from "./components/routes/Collectives";
import Request from "./components/routes/LegalRequest";
import "./style.scss";

function WritingsIta({ match }) {
  return (
    <Switch>
      <Route
        path={`${match.url}/articoli-di-alviani`}
        component={({ match }) => (
          <Articles loc="it" type="by" start={1960} end={null} match={match} />
        )}
      />
      <Route
        path={`${match.url}/articoli-su-alviani`}
        component={({ match }) => (
          <Articles loc="it" type="on" start={1960} end={null} match={match} />
        )}
      />
      <Route path={`${match.url}/nuove-tendenze`} component={Article} />

      <Route
        path={`${match.url}/of/`}
        component={() => <Redirect to={`/it/scritti/articoli-di-alviani/`} />}
      />
      <Route
        path={`${match.url}/about/`}
        component={() => <Redirect to={`/it/scritti/articoli-su-alviani/`} />}
      />
      <Route
        path={`${match.url}/new-tendencies/`}
        component={() => <Redirect to={`/it/scritti/nuove-tendenze/`} />}
      />

      <Route component={FourOhFour} />
    </Switch>
  );
}
function WritingsEng({ match }) {
  return (
    <Switch>
      <Route
        path={`${match.url}/of/`}
        component={({ match }) => (
          <Articles loc="en" type="by" start={1960} end={null} match={match} />
        )}
      />
      <Route
        path={`${match.url}/about/`}
        component={({ match }) => (
          <Articles loc="en" type="on" start={1960} end={null} match={match} />
        )}
      />
      <Route path={`${match.url}/new-tendencies`} component={Article} />

      <Route
        path={`${match.url}/articoli-di-alviani`}
        component={() => <Redirect to={`/en/writings/of/`} />}
      />
      <Route
        path={`${match.url}/articoli-su-alviani`}
        component={() => <Redirect to={`/en/writings/about/`} />}
      />
      <Route
        path={`${match.url}/nuove-tendenze`}
        component={() => <Redirect to={`/en/writings/new-tendencies/`} />}
      />

      <Route component={FourOhFour} />
    </Switch>
  );
}
function ExhibitionsIta({ match }) {
  return (
    <Switch>
      <Route path={`${match.url}/personali`} component={Personals} />
      <Route path={`${match.url}/collettive`} component={Collectives} />
      <Route path={`${match.url}/grafica`} component={Graphic} />

      <Route
        path={`${match.url}/personals`}
        component={() => <Redirect to={`/it/mostre/personali`} />}
      />
      <Route
        path={`${match.url}/collectives`}
        component={() => <Redirect to={`/it/mostre/collettive`} />}
      />
      <Route
        path={`${match.url}/graphic`}
        component={() => <Redirect to={`/it/mostre/grafica/`} />}
      />

      <Route component={FourOhFour} />
    </Switch>
  );
}

function ExhibitionsEng({ match }) {
  return (
    <Switch>
      <Route path={`${match.url}/personals`} component={Personals} />
      <Route path={`${match.url}/collectives`} component={Collectives} />
      <Route path={`${match.url}/graphic`} component={Graphic} />

      <Route
        path={`${match.url}/personali`}
        component={() => <Redirect to={`/en/exhibitions/personals`} />}
      />
      <Route
        path={`${match.url}/collettive`}
        component={() => <Redirect to={`/en/exhibitions/collectives`} />}
      />
      <Route
        path={`${match.url}/grafica`}
        component={() => <Redirect to={`/en/exhibitions/graphic/`} />}
      />

      <Route component={FourOhFour} />
    </Switch>
  );
}

function English({ match, history }) {
  return (
    <>
      <Menu history={history} match={match} />
      <Switch>
        <Route exact path={`${match.url}`} component={Index} />
        <Route path={`${match.url}/biography`} component={Biography} />
        <Route path={`${match.url}/in-museums`} component={Where} />
        <Route path={`${match.url}/works`} component={Works} />
        <Route path={`${match.url}/exhibitions`} component={ExhibitionsEng} />
        <Route path={`${match.url}/mostre`} component={ExhibitionsEng} />
        <Route
          path={`${match.url}/certificate-of-authenticity`}
          component={Request}
        />
        <Route
          path={`${match.url}/autentica`}
          component={() => <Redirect to="/en/certificate-of-authenticity" />}
        />
        <Route
          path={`${match.url}/opere-essenziali`}
          component={() => <Redirect to="/en/works" />}
        />
        <Route
          path={`${match.url}/nei-musei`}
          component={() => <Redirect to="/en/in-museums" />}
        />
        <Route
          path={`${match.url}/biografia`}
          component={() => <Redirect to="/en/biography" />}
        />
        <Route path={`${match.url}/writings`} component={WritingsEng} />
        <Route
          path={`${match.url}/scritti/:var`}
          component={({ match }) => (
            <Redirect to={`/en/writings/${match.params.var}`} />
          )}
        />
        <Route component={FourOhFour} />
      </Switch>
    </>
  );
}
function Italian({ match, history }) {
  return (
    <>
      <Menu history={history} match={match} />
      <Switch>
        <Route exact path={`${match.url}`} component={Index} />
        <Route path={`${match.url}/biografia`} component={Biography} />
        <Route path={`${match.url}/nei-musei`} component={Where} />
        <Route path={`${match.url}/opere-essenziali`} component={Works} />
        <Route path={`${match.url}/scritti`} component={WritingsIta} />
        <Route path={`${match.url}/mostre`} component={ExhibitionsIta} />
        <Route path={`${match.url}/exhibitions`} component={ExhibitionsEng} />
        <Route path={`${match.url}/autentica`} component={Request} />
        <Route
          path={`${match.url}/works`}
          component={({ match }) => <Redirect to={`/it/opere-essenziali`} />}
        />
        <Route
          path={`${match.url}/certificate-of-authenticity`}
          component={({ match }) => <Redirect to={`/it/autentica`} />}
        />
        <Route
          path={`${match.url}/writings/:var`}
          component={({ match }) => (
            <Redirect to={`/it/scritti/${match.params.var}`} />
          )}
        />
        <Route
          path={`${match.url}/biography`}
          component={({ match }) => <Redirect to={`/it/biografia`} />}
        />
        <Route
          path={`${match.url}/works/:var`}
          component={({ match }) => (
            <Redirect to={`/it/opere/${match.params.var}`} />
          )}
        />
        <Route
          path={`${match.url}/in-museums/`}
          component={() => <Redirect to={`/it/nei-musei/`} />}
        />
        <Route component={FourOhFour} />
      </Switch>
    </>
  );
}

function App(props) {
  return (
    <Router>
      <MenuContextProvider>
        <Switch>
          <Route exact path="/" component={() => <Redirect to={`/it/`} />} />
          <Route path="/en/" component={English} />
          <Route path="/it/" component={Italian} />
          <Route component={FourOhFour} />
        </Switch>
      </MenuContextProvider>
    </Router>
  );
}

export default App;
