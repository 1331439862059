import React, {useContext} from "react";
import { MenuContext } from "../../context/Menu";
import LanguageLinks from "./LanguageLinks";

function Top(props) {
  const { state, dispatch } = useContext(MenuContext);
    let urlArray = props.match.url.split("/");
    const currentLoc = urlArray[1];
    urlArray.splice(0, 2);
    const delocalizedPath = urlArray.join("/");
    const toggleMenu = () => dispatch({ type: "toggle" });
  return (
    <div className="heading">
      <div className="contents">
          <div className="logo" onClick={toggleMenu}></div>
          <div className="language">
          <span
            className={state.visible ? "clickable bold" : "clickable"}
            onClick={toggleMenu}
          >
            Menu
          </span>
              <LanguageLinks loc={currentLoc} path={delocalizedPath} />
          </div>
      </div>
    </div>
  );
}

export default Top;