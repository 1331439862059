import React from 'react';

function Text(props){
    return(
        <>
          {(props.title)?<h2>{props.title}</h2>:null}
          <p>{props.text}</p>  
        </>
    );
}

export default Text;