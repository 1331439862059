import React from "react";
import Footer from "./../parts/Footer";
import Top from "./../parts/Top";
import StickyTop from "./../parts/StickyTop";
import allDocs from "../../assets/docs/all-docs.zip";
import registration from "../../assets/docs/registrazione.pdf";
import card from "../../assets/docs/scheda.pdf";
import privacy from "../../assets/docs/informativa.pdf";

function Request(props) {
  const localization = props.match.url.split("/")[1];
  const Ita = () => {
    return (
      <>
        <h1>Richiesta autentica</h1>
        <p>
          è in corso, presso il centro studi archivio e ricerche getulio alviani, l'archiviazione
          delle opere del maestro getulio alviani in previsione della redazione del catalogo
          ragionato. tutti coloro che sono interessati sono invitati ad inoltrare la documentazione
          relativa ad ogni opera in loro possesso al centro studi archivio e ricerche getulio
          alviani, autorizzato alla raccolta e all’archiviazione del materiale.
        </p>
        <p>
          l’archivio svolge un servizio di catalogazione legato all’emissione di un certificato di
          autentica su richiesta degli stessi collezionisti, delle gallerie, dei musei e dei
          mercanti in vista della pubblicazione dei diversi volumi previsti del catalogo ragionato.
          per sapere se l’opera in proprio possesso è autentica, e richiedere quindi il suo esame da
          parte della commissione dell’archivio, occorre seguire determinate procedure.
        </p>
        <ol className="ordered-list">
          <li>
            <h2>Regolamento per la richiesta d’autentica ed archiviazione opere</h2>
            prima di iniziare la procedura per la richiesta di registrazione di qualsiasi opera di
            getulio alviani è necessario inviare una mail con i dati del richiedente all’indirizzo
            e-mail <a href="mailto:archivio@getulioalviani.com">archivio@getulioalviani.com</a>{" "}
            includendo:
            <ul className="unordered-list">
              <li>
                la scheda tecnica
                <a href={card}>
                  <span className="bold"> (scarica qui) </span>
                </a>
                preliminare compilata in tutte le sue voci;
              </li>
              <li>
                la riproduzione digitale dell'opera fronte/retro, con eventuali dettagli (firma,
                anno, numerazione, annotazioni, timbri, etichette, dediche se presenti).
              </li>
            </ul>
            una volta ricevuta la documentazione via mail sarà cura dell’archivio verificare il
            materiale e specificare al richiedente come procedere.
          </li>
          <li>
            <h2>Procedura per la richiesta di registrazione</h2>
            attenzione: la documentazione per la registrazione si potrà inviare successivamente
            all'avvenuto controllo preliminare dell'opera di cui al punto 1 per avviare la richiesta
            di autenticazione di qualsiasi opera di getulio alviani occorre inviare la seguente
            documentazione presso l’archivio:
            <ul className="unordered-list">
              <li>n.3 stampe fotografiche a colori del fronte dell’opera (formato di cm 18×24);</li>
              <li>n.3 stampe fotografiche a colori del retro dell’opera (formato di cm 18×24);</li>
              <li>
                n.1 stampa fotografica a colori di firma e/o iscrizioni/timbri/etichette se presenti
                sull’opera;
              </li>
              <li>
                i file digitali del fronte, del retro, delle firme, dei particolari in alta
                definizione (almeno 13 x 18 cm a 300 dpi), in formato jpg e tiff, e su supporto cd;
              </li>
              <li>
                la scheda tecnica preliminare
                <a href={card}>
                  <span className="bold"> (scarica qui) </span>
                </a>
                debitamente compilata in tutte le sue voci e sottoscritta;
              </li>
              <li>
                la procedura di registrazione
                <a href={registration}>
                  <span className="bold"> (scarica qui) </span>
                </a>
                e l’informativa sulla privacy
                <a href={privacy}>
                  <span className="bold"> (scarica qui) </span>
                </a>
                debitamente compilate e sottoscritte;
              </li>
              <li>
                la copia della ricevuta di eseguito bonifico bancario recante codice riferimento
                operazione (CRO) dell’importo che verrà comunicato dal Centro Studi Archivio e
                Ricerche Getulio Alviani.
                <p>
                  dati bancari per il bonifico:
                  <br />
                  c/o banca Unicredit <br />
                  intestato a centro studi archivio e ricerche getulio alviani
                  <br />
                  IBAN: IT04s0200861071000105998252
                  <br />
                  bic/swift: uncritm1m81
                  <br />
                  causale: contributo archivio per richiesta di autentica e archiviazione
                </p>
                <p>
                  l’archiviazione e l’autenticazione sono a pagamento, esso costituisce un rimborso
                  forfettario per le spese e le attività relative alla consulenza e, anche nel caso
                  in cui le opere non siano attribuibili a getulio alviani, non sarà in alcun caso
                  rimborsabile.
                  <p>
                    chi effettua il pagamento deve compilare con i propri dati il modulo per la
                    ricevuta:
                  </p>
                  <ul className="unordered-list">
                    <li>
                      fotocopia del documento d’identità del proprietario/oppure visura camerale
                      dell’impresa;
                    </li>
                    <li>l’originale dell’autentica, se in possesso;</li>
                    <li>
                      documenti e testimonianze (fotocopie di carteggi, volumi, cataloghi, articoli,
                      manoscritti, fotografie, filmati, interviste, ecc.) relative alla storia delle
                      opere ed eventualmente alla figura del maestro;
                    </li>
                    <li>
                      nei casi di valutazione positiva il richiedente dovrà inviare sia via mail che
                      via posta celere l’autorizzazione scritta per la pubblicazione in catalogo
                      ragionato del proprio nominativo autorizzando il trattamento dei dati
                      personali senza questa autorizzazione, verrà utilizzata la dicitura
                      "collezione privata".
                    </li>
                  </ul>
                  <p>
                    al fine dell’analisi e dell’esame per l’eventuale inserimento dell’opera
                    nell’archivio del centro studi archivio e ricerche getulio alviani sarà
                    necessario un periodo minimo di 90 giorni dal ricevimento della documentazione
                    sopra richiesta.
                  </p>
                  <p>
                    l’archivio potrebbe richiedere di esaminare l’opera in originale presso la
                    propria sede in occasione delle periodiche sedute periziali del comitato tecnico
                    scientifico.
                  </p>
                  <p>
                    la consulenza del comitato scientifico dell’archivio non è a pagamento. nel caso
                    in cui il comitato scientifico dell’archivio riterrà necessario l’intervento di
                    consulenti esterni (esperti calligrafi, analisi chimiche, conservatori) per
                    ulteriori approfondimenti tecnico-scientifici il relativo costo sarà addebitato
                    al richiedente previa autorizzazione a procedere in tale senso e senza
                    garantirne l’esito.
                  </p>
                  <p>
                    il parere emesso dall’archivio verrà comunicato sotto forma di lettera, inviata
                    tramite raccomandata.
                  </p>
                  <p>
                    il centro studi non rilascia duplicati di autentiche che siano già state fatte
                    in precedenza se non ripetendo l’intera procedura di archiviazione.
                  </p>
                </p>
              </li>
            </ul>
          </li>
        </ol>
        <h2>Files</h2>
        <p>
          <a href={card}>
            <span className="bold">>> Scheda tecnica preliminare</span>
          </a>
        </p>
        <p>
          <a href={registration}>
            <span className="bold">>> Procedura registrazione</span>
          </a>
        </p>
        <p>
          <a href={privacy}>
            <span className="bold">>> Informativa e consenso trattamento dati</span>
          </a>
        </p>
      </>
    );
  };
  const Eng = () => {
    return (
      <>
        <h1>Request a certificate of authenticity</h1>
        <p>
          "Centro Studi Archivio e Ricerche Getulio Alviani" can issue a certificate of authenticity
          for the works of Getulio Alviani upon request of interested collectors. To know if the
          work in your possession is authentic and therefore request its examination by the
          commission of "Centro Studi Archivio e Ricerche Getulio Alviani", it is necessary to
          follow the procedure described in the documents you can find below (written in Italian
          language). Please send all the documents to via Fauchè 1, 20154 Milano Italy, with
          delivery times between 8 am and 12 am.
        </p>
        <h2>Files</h2>
        <p>
          <a href={allDocs}>
            <span className="bold">
              >> rules and documents to fill to request the certificate of authenticity (ita)
            </span>
          </a>
        </p>
      </>
    );
  };
  return (
    <>
      <StickyTop match={props.match} />
      <Top match={props.match} />
      <div className="wrapper">
        {localization === "it" ? <Ita /> : <Eng />}
        <div className="img-square-container">
          <div className="square-autentica" />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Request;
