import React from "react";
import Text from "./../parts/Text";
import Footer from "./../parts/Footer";
import Top from "./../parts/Top";
import StickyTop from "./../parts/StickyTop";
const data = require("./../../data/articles.json");


function Article(props) {
  console.log(props);
  const localization = props.match.url.split("/")[1];
  return (
    <>
    <StickyTop match={props.match} />
    <Top match={props.match} />
    <div className="wrapper">
    <h2>{data[localization].full[0].title}</h2>
    <div className="nuove-tendenze-image"></div>
        {
            data[localization].full[0].text.map((paragraph, key) => <Text key={key} text={paragraph} />)
        }
    </div>
    <Footer />
    </>
  );
}

export default Article;