import React from "react";
import Work from "./../parts/Work";
import Footer from "./../parts/Footer";
import Top from "./../parts/Top";
import StickyTop from "./../parts/StickyTop";
const data = require("./../../data/works.json");

function Biography(props) {
  const localization = props.match.url.split("/")[1];
  return (
    <>
      <StickyTop match={props.match} />
      <Top match={props.match} />
      <div className="wrapper">
        <h1>{(localization === "it")?"Opere essenziali":"Works"}</h1>
        <p>{(localization === "it")?"Viene qui presentata una selezione delle opere essenziali di Getulio Alviani. Per prendere visione di altro materiale o richiedere informazioni sulle opere, si prega di contattarci scrivendo una mail all'indirizzo in calce al sito.":"Here is presented a selection of the essential works of Getulio Alviani. To view other material or request information about these or other works, please contact us by writing an email at the address you can find in the footer of the website."}</p>
        <div className="works">
          {data[localization].map((work, key) => (
            <Work
              key={key}
              type={work.type}
              title={work.title}
              description={work.description}
              uri={work.img_uri}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Biography;
