import React, { useContext } from "react";
import { MenuContext } from "../../../context/Menu";

function Articles(props) {
  const { dispatch } = useContext(MenuContext);
  function resetMenu() {
    dispatch({ type: "subMenu", value: null });
  }
  function goTo(event) {
    dispatch({ type: "toggle" });
    props.history.push(event.target.getAttribute("to"));
  }
  if(props.loc === "it"){
    return (
      <>
        <span className="link" onClick={resetMenu}>
          {"<<"}
        </span>
        <span className="link" to="/it/scritti/articoli-di-alviani" onClick={goTo}>Articoli di Alviani</span>
        <span className="link" to="/it/scritti/articoli-su-alviani" onClick={goTo}>Articoli su Alviani</span>
        <span className="link" to="/it/scritti/nuove-tendenze" onClick={goTo}>Nuove tendenze</span>
      </>
    );
  } else {
    return (
      <>
        <span className="link" onClick={resetMenu}>
          {"<<"}
        </span>
        <span className="link" to="/en/writings/of" onClick={goTo}>Of</span>
        <span className="link" to="/en/writings/about" onClick={goTo}>About</span>
        <span className="link" to="/en/writings/new-tendencies" onClick={goTo}>New tendencies</span>
      </>
    );
  }
  
}

export default Articles;
