import React, { useReducer } from "react";

const MenuContext = new React.createContext();

let initialState = {
  visible: false,
  subMenu: null
};

let reducer = (state, action) => {
  //toggle menu
  if (action.type === "toggle") {
    return {
      ...state,
      visible: !state.visible
    };
  }
  //open subMenu
  if (action.type === "subMenu") {
    return {
      ...state,
      subMenu : action.value
    };
  }
};

function MenuContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  let value = { state, dispatch };
  return (
    <MenuContext.Provider value={value}>{props.children}</MenuContext.Provider>
  );
}

let MenuContextConsumer = MenuContext.Consumer;

export { MenuContext, MenuContextProvider, MenuContextConsumer };
