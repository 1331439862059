import React /*, { useState, useEffect }*/ from "react";
import Top from "./../parts/Top";
import StickyTop from "./../parts/StickyTop";
import { Helmet } from "react-helmet";
//import Cookies from "universal-cookie";

//const cookies = new Cookies();

function Index(props) {
  /*const [eModal, setEModal] = useState(true);
  useEffect(() => {
    setTimeout(() => setEModal(false), 500);
  }, []);
  if (!cookies.get("venice")) {
    cookies.set("venice", 0);
  }*/
  const localization = props.match.url.split("/")[1];
  return (
    <>
      <Helmet>
        <title>Centro Studi e Ricerche Getulio Alviani</title>
        <meta
          name="description"
          content={
            localization === "it"
              ? "Il Centro Studi Archivio e Ricerche Getulio Alviani garantisce la conoscenza e la valorizzazione dell’opera del Maestro"
              : "Centro Studi Archivio e Ricerche Getulio Alviani is a foundation born to carry the legacy and the work of the Master to future generations, committed to the promotion of art and culture."
          }
        />
      </Helmet>
      <Top match={props.match} />
      <StickyTop match={props.match} />
      <div className="wrapper">
        <div className="splash-image" />
        <p className="index-text">
          {localization === "it"
            ? "Il Centro Studi Archivio e Ricerche Getulio Alviani garantisce la conoscenza e la valorizzazione dell’opera del Maestro, si occupa della promozione di arte e cultura con particolare riguardo alle Avanguardie e alle ricerche artistiche avanzate e sostiene attività di ricerca e formazione in linea con il proprio statuto."
            : "Centro Studi Archivio e Ricerche Getulio Alviani is a foundation born to carry the legacy and the work of the Master to future generations. Committed to the promotion of art and culture, especially if linked to avant-garde movements, supports research and education activities that are consistent with its statute."}
        </p>
        <div className="credits">
          <p>
            &copy; Centro Studi Archivio e Ricerche Getulio Alviani
            <br />
            Via Fauché, 1 - 20154 Milano
            <br />
            <a href="mailto:archivio@getulioalviani.com">archivio(at)getulioalviani.com</a>
          </p>
        </div>
        {/*<div className={(parseInt(cookies.get('venice')) > 1 || eModal)?"highlight-box hidden":"highlight-box"}>
          <div className="contents">
            <div className={eModal?"modal":"modal visible"}>
            <div className="boxed-modal-image">
              
            </div>
              <div className="close-modal" onClick={() => {
                setEModal(true);
                cookies.set('venice', parseInt(cookies.get('venice')) + 1);
                }}>x</div>
              <div className="ant-img">
                <div className="inner-content">
                  <h3 className="main">Getulio Alviani</h3>
                  <h3 className="secondary">Anthropometry</h3>
                  <h4 className="quote">{(localization === 'it')?'L\'amore mio è il futuro':'Future is the love of mine'}</h4>
                  <p>
                  {(localization === 'it')?'Dal 9 Maggio al 30 Giugno 2019':'Starting May 9th till June 30th'}<br />
                    Palazzo Barbaro, {(localization === 'it')?'Venezia':'Venice'}.
                  </p>
                </div>
                <div className="partners">
                  <div className="skira-logo"></div>
                </div>
                <a href="https://anthropometry.eventbrite.com/?aff=website"><div className="link">{(localization === 'it')?'Scopri di più':'Discover more'}</div></a>
              </div>
            </div>
          </div> 
        </div>*/}
      </div>
    </>
  );
}

export default Index;
