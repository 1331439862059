import React, { useContext } from "react";
import { MenuContext } from "../../../context/Menu";
import Writings from "./Writings";
import Exhibitions from "./Exhibitions";

function FirstLevel(props) {
  const { state, dispatch } = useContext(MenuContext);
  function goTo(event) {
    dispatch({ type: "toggle" });
    props.history.push(event.target.getAttribute("to"));
  }
  function subMenu(event) {
    dispatch({ type: "subMenu", value: event.target.getAttribute("submenu") });
  }
  if (props.loc === "it") {
    return (
      <>
        <div className={state.subMenu ? "first-level hidden" : "first-level"}>
          <span className="link" to="/it/" onClick={goTo}>
            Home
          </span>
          <span className="link" to="/it/biografia" onClick={goTo}>
            Biografia
          </span>
          <span className="link" to="/it/nei-musei" onClick={goTo}>
            Alviani nei musei
          </span>
          <span className="link" submenu="writings" onClick={subMenu}>
            Scritti
          </span>
          <span className="link" to="/it/opere-essenziali" onClick={goTo}>
            Opere essenziali
          </span>
          <span className="link" submenu="exhibitions" onClick={subMenu}>
            Mostre
          </span>
          <span className="link" to="/it/autentica" onClick={goTo}>
            Richiesta autentica
          </span>
        </div>
        <div className={state.subMenu ? "second-level" : "second-level hidden"}>
          {state.subMenu === "writings" ? (
            <Writings loc={props.loc} history={props.history} />
          ) : null}
          {state.subMenu === "exhibitions" ? (
            <Exhibitions loc={props.loc} history={props.history} />
          ) : null}
        </div>
      </>
    );
  }
  if (props.loc === "en") {
    return (
      <>
        <div className={state.subMenu ? "first-level hidden" : "first-level"}>
          <span className="link" to="/en/" onClick={goTo}>
            Home
          </span>
          <span className="link" to="/en/biography" onClick={goTo}>
            Biography
          </span>
          <span className="link" to="/en/in-museums" onClick={goTo}>
            Alviani in museums
          </span>
          <span className="link" submenu="writings" onClick={subMenu}>
            Writings
          </span>
          <span className="link" to="/en/works" onClick={goTo}>
            Works
          </span>
          <span className="link" submenu="exhibitions" onClick={subMenu}>
            Exhibitions
          </span>
          <span
            className="link"
            to="/en/certificate-of-authenticity"
            onClick={goTo}
          >
            Request certificate of authenticity
          </span>
        </div>
        <div className={state.subMenu ? "second-level" : "second-level hidden"}>
          {state.subMenu === "writings" ? (
            <Writings loc={props.loc} history={props.history} />
          ) : null}
          {state.subMenu === "exhibitions" ? (
            <Exhibitions loc={props.loc} history={props.history} />
          ) : null}
        </div>
      </>
    );
  }
}

export default FirstLevel;
