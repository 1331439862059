import React from "react";
//import Footer from "./../parts/Footer";
import StickyTop from "./../parts/StickyTop";
import Top from "./../parts/Top";

function FourOhFour(props) {
  return (
    <>
      <StickyTop match={props.match} />
      <Top match={props.match} />
      <div className="wrapper">
        <h1>Page not found / Pagina non trovata</h1>
        <div className="credits">
          <p>
            &copy; Centro Studi Archivio e Richerche Getulio Alviani
            <br />
            Via Mecenate, 76 - 20138 Milano
            <br />
            <a href="mailto:archivio@getulioalviani.com">archivio(at)getulioalviani.com</a>
          </p>
        </div>
      </div>
    </>
  );
}

export default FourOhFour;
