import React from "react";

function Footer(props) {
  return (
    <div className="footer">
      <div className="contents">
        <p>
          &copy; Centro Studi Archivio e Richerche Getulio Alviani
          <br />
          Via Mecenate 76 - 20138 Milano
          <br />
          <a href="mailto:archivio@getulioalviani.com">archivio(at)getulioalviani.com</a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
