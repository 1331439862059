import React from "react";

function Work(props) {
  const link = require('./../../assets/works/' + props.uri);
  return <div className="work">
    <img src={link} className="work-image" alt={props.title} />
    <p>{props.type}</p>
    <p>{props.title}<br />{props.description}<br />{props.size}</p>
  </div>;
}

export default Work;
