import React from "react";
import RichList from "./RichList";
import Footer from "./Footer";
import Top from "./Top";
import StickyTop from "./StickyTop";
const data = require("./../../data/articles.json");

function Publications(props) {
    function condition(item, start, end){
        if(!start && end){
            return (item.year <= end);
        } else if (start && !end){
            return (item.year > start);
        } else {
            return (item.year > start && item.year <= end);
        }
    }
  return (
    <>
      <StickyTop match={props.match} />
      <Top match={props.match} />
      <div className="wrapper">
        <RichList data={ data[props.loc][props.type].filter(item => condition(item, props.start, props.end)) } />
      </div>
      <Footer />
    </>
  );
}

export default Publications;