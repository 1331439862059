import React from "react";
import Text from "./../parts/Text";
import Footer from "./../parts/Footer";
import Top from "./../parts/Top";
import StickyTop from "./../parts/StickyTop";
const data = require("./../../data/bio.json");

function Biography(props) {
  const localization = props.match.url.split("/")[1];
  return (
    <>
      <StickyTop match={props.match} />
      <Top match={props.match} />
      <div className="wrapper">
        <div className="bio-video">
          <iframe
            title="Ulrich Obrist in conversazione con Getulio Alviani (2015)"
            src="https://player.vimeo.com/video/129987948"
            width="100%"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          />
        </div>
        <h1>{localization === "it" ? "Biografia" : "Biography"}</h1>
        {data[localization].text.map((paragraph, key) => (
          <Text key={key} text={paragraph} />
        ))}
        <div className="bio-image" />
      </div>
      <Footer />
    </>
  );
}

export default Biography;
