import React from "react";
import { Link } from "react-router-dom";

function LanguageLinks(props) {
  if (props.loc === "it") {
    return (
      <>
        <span className="bold"> ita </span>/
        <Link to={"/en/" + props.path}> eng </Link>
      </>
    );
  } else {
    return (
      <>
        <Link to={"/it/" + props.path}> ita </Link>/
        <span className="bold"> eng </span>
      </>
    );
  }
}

export default LanguageLinks;
