import React, { useContext } from "react";
import { MenuContext } from "../../../context/Menu";

function Exhibitions(props) {
  const { dispatch } = useContext(MenuContext);
  function resetMenu() {
    dispatch({ type: "subMenu", value: null });
  }
  function goTo(event) {
    dispatch({ type: "toggle" });
    props.history.push(event.target.getAttribute("to"));
  }
  if(props.loc === "it"){
    return (
      <>
        <span className="link" onClick={resetMenu}>
          {"<<"}
        </span>
        <span className="link" to="/it/mostre/personali" onClick={goTo}>Personali</span>
        <span className="link" to="/it/mostre/collettive" onClick={goTo}>Collettive</span>
        <span className="link" to="/it/mostre/grafica" onClick={goTo}>Grafica</span>
      </>
    );
  } else {
    return (
      <>
        <span className="link" onClick={resetMenu}>
          {"<<"}
        </span>
        <span className="link" to="/en/exhibitions/personals" onClick={goTo}>Personals</span>
        <span className="link" to="/en/exhibitions/collectives" onClick={goTo}>Collectives</span>
        <span className="link" to="/en/exhibitions/graphic" onClick={goTo}>Graphic</span>
      </>
    );
  }
  
}

export default Exhibitions;