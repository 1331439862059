import React from "react";
import List from "./../parts/List";
import Footer from "./../parts/Footer";
import Top from "./../parts/Top";
import StickyTop from "./../parts/StickyTop";
const museums = require("./../../data/museums");

function Where(props) {
  const localization = props.match.url.split("/")[1];
  return (
    <>
      <StickyTop match={props.match} />
      <Top match={props.match} />
      <div className="wrapper">
        <List title={localization==="it"?"Musei in Italia":"Italian museums"} data={museums[localization].italy.map(item => (<>{item.name}, <span className="bold">{item.city}</span></>))} />
        <List title={localization==="it"?"Musei nel mondo":"Worldwide"} data={museums[localization].world.map(item => (<>{item.name}, <span className="bold">{item.city}</span></>))} />
      </div>
      <Footer />
    </>
  );
}

export default Where;