import React from "react";
import Footer from "./../parts/Footer";
import Top from "./../parts/Top";
import StickyTop from "./../parts/StickyTop";
const data = require("./../../data/collectives.json");

function Collectives(props) {
  const localization = props.match.url.split("/")[1];
  return (
    <>
      <StickyTop match={props.match} />
      <Top match={props.match} />
      <div className="wrapper">
        <h1>{localization === "it" ? "Mostre collettive" : "Collective Exhibitions"}</h1>
        <div className="img-square-container">
          <div className="square-collectives"></div>
        </div>
        <ul className="decorated-list">
          {data.exhibitions.map((y, key) => (
            <li key={key}>
              <h2>{y.title}</h2>
              {y.editions.map((ed, key) => (
                <div className="edition" key={key}>
                  <span className="title">{ed.gallery ? ed.gallery + ", " : null}</span>
                  {ed.city ? ed.city : null}{" "}
                  {ed.year ? <span className="city">{ed.year}</span> : null}
                </div>
              ))}
            </li>
          ))}
        </ul>
      </div>
      <Footer />
    </>
  );
}

export default Collectives;
