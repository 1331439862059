import React from "react";

function List(props) {
  return (
    <>
      {props.title ? <h2>{props.title}</h2> : null}
      <ul className="decorated-list">
        {props.data.map((item, key) => (
          <li key={key}>{item}</li>
        ))}
      </ul>
    </>
  );
}

export default List;
